<script>

  export default {
    name: "help-faq",
    data: function() {
      return {
        questions: []
      }
    },
    created() {
      this.$store.dispatch("pushPage", this.$route.name)
      this.$store.commit("setBackLogic", () => {
        var previousPage = this.$store.getters.previousPage

        if (previousPage == null) {
          this.$router.replace({name: "home"})
        } else {
          this.$store.dispatch("popPage")

          if (previousPage.startsWith("quest-"))
            this.$router.replace({name: "questionnaire"})
          else
            this.$router.replace({name: previousPage})
        }
      })
    },
    mounted() {
      this.questions = this.$t("questions")
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop">
          <div class="block">
            <h1 class="title has-text-centered is-3">
              {{ $t("title") }}
            </h1>
          </div>

          <div class="block">
            <div class="box" v-for="(question, index) in questions" v-bind:key="index">
              <p class="mb-2 is-size-4"> {{ Number(index) + 1 }}. {{ question.question }} </p>
              <p class="has-text-justified"> {{ question.answer }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "title": "FAQ TRAC19",
    "questions": [
      {
        "question": "Cos'è TRAC19?",
        "answer": "TRAC19 è una applicazione web che consente la gestione rapida e sostenibile degli accessi in ambiti sanitari (es. ospedali, case di cura, assistenza) ed extra-sanitari (es. scuole, residenze universitarie, teatri, musei, cinema, aeroporti, piscine, centri sportivi, hotel, spiagge), e supporta l’attuazione di misure di contrasto alla diffusione del Covid19. Un vero e proprio lasciapassare telematico, compilato in autonomia via web, che permette un accesso semplice e sicuro a tutte le strutture a forte rischio Covid19."
      }, {
        "question": "Come funziona TRAC19?",
        "answer": "Tutto quello che devi fare è scegliere il luogo in cui vuoi recarti, registrare il tuo indirizzo e-mail, compilare un questionario di autovalutazione dei tuoi sintomi e mostrare al personale della struttura il codice di accesso che riceverai all’e-mail da te indicata."
      }, {
        "question": "La mia privacy è a rischio?",
        "answer": "NO. TRAC19 protegge la tua privacy, conservando solamente il tuo indirizzo e-mail in forma cifrata. Il questionario compilato tramite TRAC19 non viene memorizzato. Sarai tu a decidere con chi condividerlo, accedendo a una struttura e consentendo al personale di verificare la tua identità. Per esercitare i diritti sanciti dal GDPR, consulta l’Informativa Privacy."
      }, {
        "question": "Perché TRAC19 ha bisogno della mia e-mail?",
        "answer": "TRAC19 utilizza la tua e-mail per inviarti il codice di accesso alle strutture."
      }, {
        "question": "Siamo due persone diverse. Possiamo usare la stessa e-mail?",
        "answer": "SÌ. TRAC19 non identifica gli utenti. Più persone possono utilizzare la stessa e-mail per compilare il questionario."
      }, {
        "question": "Siamo due persone che utilizzano la stessa e-mail. Come facciamo a distinguere i nostri questionari se TRAC19 non conosce le nostre identità?",
        "answer": "Potete inserire una nota dopo la compilazione per distinguere i vostri questionari. Troverete la nota inserita nell’e-mail insieme al codice di accesso. La nota non viene salvata da TRAC19 per proteggere la vostra privacy."
      }, {
        "question": "Devo andare in ospedale per una visita, ma ho bisogno di un accompagnatore. Come dobbiamo fare?",
        "answer": "Dovete compilare entrambi il questionario di autovalutazione, utilizzando la stessa e-mail o e-mail distinte. Ricordatevi di inserire una nota per recuperare facilmente il vostro questionario nella casella di posta. Una volta giunti in struttura, mostrate al personale i codici ricevuti via e-mail."
      }, {
        "question": "Mia/o figlia/o è minorenne. Come devo fare per la compilazione dei questionari?",
        "answer": "Devi compilare due questionari distinti: uno per te e l’altro per tua/o figlia/o. Dovrai accompagnare tua/o figlia/o in struttura."
      }, {
        "question": "Sono minorenne. Posso compilare il questionario?",
        "answer": "NO. Devi far compilare il questionario ai tuoi genitori o a chi fa le tue veci."
      }, {
        "question": "Posso sapere come TRAC19 tratta i miei dati personali?",
        "answer": "Certo. Puoi consultare l’Informativa Privacy."
      }, {
        "question": "Voglio modificare le autorizzazioni al trattamento dei miei dati personali. Come devo fare?",
        "answer": "Ti invitiamo a consultare l’Informativa Privacy. Troverai tutti i tuoi diritti e le indicazioni da seguire per modificare i tuoi consensi."
      }, {
        "question": "Con quanto anticipo devo compilare il questionario per accedere a una struttura?",
        "answer": "La validità del questionario è stabilita dalla struttura ed è visibile quando selezioni la struttura prima della compilazione del questionario."
      }, {
        "question": "Posso registrare più e-mail in TRAC19?",
        "answer": "SI. Non c’è un limite al numero di e-mail che puoi registrare."
      }, {
        "question": "Chi ha realizzato TRAC19?",
        "answer": "TRAC19 è stata realizzata da AIVOLUTION, una società fondata da ricercatori e professori universitari esperti di Intelligenza Artificiale."
      }, {
        "question": "Non ho trovato la risposta che cercavo. Cosa posso fare?",
        "answer": "Puoi scrivere una e-mail all’indirizzo assistenza@trac19.it. Ti risponderemo nel tempo più rapido possibile."
      }
    ]
  }
}
</i18n>

<style>
  .my-bigbutton{
    padding: 100px !important;
    width: 100%;
  }

  .my-bigbutton > img {
    margin-right:20px !important;
  }
</style>
